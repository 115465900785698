<template>
  <div class="centered">
    <div class="forms">
      <img src="/power.png" alt="logo" height="80px">
      <div class="input">
        <div class="inputBox">
          <label for="">Username</label>
          <input
            v-model="username"
            type="text"
          >
        </div>
        <div class="inputBox">
          <label for="">Password</label>
          <input
            v-model="password"
            type="password"
          >
        </div>
        <div class="inputBox">
          <button
            class="btn-log"
            @click="login()"
          >
            Log in
          </button>
        </div>
      </div>
      <p class="forgot">
        Forgot Password? <a href="#">Click Here</a>
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      username: '',
      Ip: null,
      ag: 7,
    }
  },
  computed: {
    // passwordToggleIcon() {
    //   return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    // },
    // imgUrl() {
    //   if (store.state.appConfig.layout.skin === 'dark') {
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
    //     return this.sideImg
    //   }
    //   return this.sideImg
    // },
  },
  mounted() {
    this.GetIp()
  },
  methods: {
    async GetIp() {
      try {
        const { data: Res } = await this.$http.get('https://api.ipify.org?format=json')
        this.Ip = Res.ip
      } catch (error) {
        console.log(error)
      }
    },
    login() {
      // this.$refs.loginForm.validate().then(success => {
      // if (success) {
      useJwt
        .login({
          username: this.username,
          password: this.password,
          agent_id: this.ag,
          Ip: this.Ip,
        })
        .then(response => {
          const userData = response.data
          useJwt.setToken(response.data.token)
          useJwt.setRefreshToken(response.data.refreshToken)
          localStorage.setItem('userData', JSON.stringify(userData))
          localStorage.setItem('CheckClick', true)
          // localStorage.setItem('token', JSON.stringify(userData).token)
          // localStorage.setItem('userData', JSON.parse(userData))
          this.$ability.update(userData.ability)
          // this.$http
          //   .get('https://njapi.betflix.lol/api/auth/checklogin')
          //   .then(res => {
          //     localStorage.setItem('CountLogin', res.data)
          //   })
          this.$router
            .push(getHomeRouteForLoggedInUser(userData.role))
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Welcome to Image Dashboard',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'You have successfully logged ',
                },
              })
            })
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
